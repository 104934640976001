@import 'colors.scss';

#couch {
    background: url('../resources/couch/couch-backdrop.png');
    position: absolute;
    top: 780px;
    width: 472px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    #couch-icon {
        width: 42px;
        height: 22px;
        background: url('../resources/couch/icon-couch.png');
        margin-top: 3px;
    }
    .couch-names {
        font-size: 24px;
        text-transform: uppercase;
        color: $main-accent;
        @include glow;
        margin-left: 10px;
        margin-top: 4px;
    }
}